<template>
  <g-card page-title="Restored Restricted Orders" page-icon="mdi-backup-restore">
    <page-overlay :overlay="isOverlay"/>
    <v-row>
      <v-col cols="6" md="6" offset-md="3">
        <v-form ref="restoredOrdersForm" v-model="formValid">
          <v-card>
            <v-toolbar flat dense class="grey lighten-2">
              Unrestricted Invoice
            </v-toolbar>
            <v-card-text>
              <v-text-field
                  outlined
                  dense
                  color="deep-purple accent-4"
                  hide-details="auto"
                  v-model="refcode"
                  :rules="requiredFieldRule"
                  label="Refcode"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <base-outlined-button
                  text="Restore"
                  color="primary"
                  icon="mdi-content-save"
                  :loading="isBtnLoading"
                  @onButtonClick="handleRestoredOrders"
              />
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </g-card>
</template>

<script>
import GCard from "../components/GCard";
import PageOverlay from "../components/PageOverlay";
import BaseOutlinedButton from "../components/buttons/BaseOutlinedButton";
import Validation from "../components/mixins/Validation";
import Invoice from "../services/Invoice";

export default {
  name: "RestoreOrders",
  components: {
    BaseOutlinedButton,
    PageOverlay,
    GCard
  },
  mixins: [Validation],
  data() {
    return {
      isOverlay: false,
      isBtnLoading: false,
      formValid: true,
      refcode: ""
    }
  },
  methods: {
    async handleRestoredOrders() {
      if (!this.$refs.restoredOrdersForm.validate()) return;
      try {
        const {data: {message}} = await Invoice.restoredRestrictedOrders({refcode: this.refcode});
        this.$store.commit('SET_SNACKBAR', {text: message, type: "info"})
        this.$refs.restoredOrdersForm.reset();
      } catch (errors) {
        this.$store.commit('SET_SNACKBAR', {text: "Something wen't wrong!!", type: "error"})
      }
    }
  }
}
</script>

<style scoped>

</style>